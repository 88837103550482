import {ApplicationRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {concat, interval, Subscription} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {filter, first} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    class: 'block bg-gray-lightest md:max-w-[480px] mx-auto shadow-lg md:rounded-t-2xl',
  },
})
export class AppComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  constructor(
    private swUpdate: SwUpdate,
    private router: Router,
    private renderer: Renderer2,
    private appRef: ApplicationRef
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      // Allow the app to stabilize first, before starting polling for swUpdate with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(() => this.swUpdate.checkForUpdate());

      if (this.swUpdate.isEnabled) {
        const sub = this.swUpdate.versionUpdates
          .pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'))
          .subscribe(event => {
            alert('New version available. New Version will be loaded.');
            console.log('current version is', event.currentVersion);
            console.log('available version is', event.latestVersion);
            document.location.reload();
          });

        this.subs.push(sub);
      }
    }

    const sub = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((route: NavigationStart) => {
        const allowedRoute = route.url === '/' || route.url === '/profile';

        if (allowedRoute) {
          this.renderer.removeClass(document.body, 'overscroll-y-contain');
        } else {
          this.renderer.addClass(document.body, 'overscroll-y-contain');
        }
      });

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach(each => each.unsubscribe());
  }
}
